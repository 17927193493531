@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "AvenirNext-Medium", sans-serif;
}

a {
  color: #84309c;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body {
  color: #F4D35E;
  background: #01153B
}

h1, h2, h3 {
  color: #c0e9ff
}

button {
  background-color: #F4D35E;
  color: #01153B;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  border-radius: 10px;
}

button:hover {
  background-color: #2e62c2;
}

/* Add responsiveness */
@media (max-width: 768px) {
  .container {
    padding: 20px;
  }
}
